<template>
  <div class="tab-pane active" id="tab-general">
    <div class="col-md-6" style="padding-left: 0;">
      <PanelBox :title="projectTitle">
        <template #toolbar>
          <span v-if="project.is_archived" class="tw-my-px label">
            Gearchiveerd
          </span>
          <a
            @click="showMapModal"
            class="toolbar-button"
            target="_blank"
            title="Geografisch">
            <i class="fa fa-globe"></i> Geografisch
          </a>
          <router-link
            :to="{ name: 'ProjectGeneralEdit', params: { formName: 'general' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </template>
        <Stickers :stickers="stickers" class="tw-mb-4" />
        <div class="row">
          <div class="col-md-6">
            <a :href="googleMapsAddress" target="_blank" title="Google Maps">
              <address>
                {{ project.street | default_if_none('') }} {{ project.number | default_if_none('') }}
                {{ project.box | default_if_none('') }}<br>
                {{ project.city.zip_code }} {{ project.city.name }}
                <br />
              </address>
            </a>
            <p class="col-sm-12 no-padding" style="padding-top: 10px!important;">
              <small v-if="project.created_on">Aangemaakt op {{ project.created_on|date-day }}
                <span v-if="project.created_by">door {{
                    project.created_by.first_name
                  }} {{ project.created_by.last_name }}</span>
              </small>
            </p>
            <p class="col-sm-12 no-padding">
              <small v-if="project.updated_on">
                Gewijzigd op {{ project.updated_on|datetime }} <span v-if="project.updated_by">door{{
                  project.updated_by.first_name
                }} {{ project.updated_by.last_name }}</span>
              </small>
            </p>
          </div>
        </div>
      </PanelBox>

      <PanelBox title="Medewerkers">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectGeneralEdit', params: { formName: 'collaborators' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <div>
          <div
            v-for="collaborator in collaborators"
            :key="collaborator.id"
            class="tw-flex tw-gap-x-3 tw-mb-2"
          >
            <button
              type="button"
              v-if="collaborator.primary"
              class="btn btn-xs btn-primary tw-w-24"
            >
              Primair
            </button>
            <button
              type="button"
              v-else
              class="btn btn-xs btn-primary btn-outline tw-w-24"
              @click="makeCollaboratorPrimary(collaborator)"
            >
              Maak primair
            </button>
            <div class="tw-flex tw-justify-center tw-w-10">
              <img
                alt="user image"
                :src="collaborator.picture || require('@/assets/img/profile.png')"
                class="tw-w-6 tw-h-6 tw-rounded-full"
              />
            </div>
            <span>{{ collaborator.display_name }}</span>
          </div>
        </div>
      </PanelBox>

      <PanelBox title="Kantoren">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectGeneralEdit', params: { formName: 'offices' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <div
          v-for="office in offices"
          :key="office.id"
          class="tw-flex tw-gap-x-3 tw-mb-2"
        >
          <button
            v-if="office.primary"
            type="button"
            class="btn btn-xs btn-primary tw-w-24"
          >
            Primair
          </button>
          <button
            v-else
            type="button"
            class="btn btn-xs btn-primary btn-outline tw-w-24"
            @click="makeOfficePrimary(office.id)"
          >
            Maak primair
          </button>
          <strong class="tw-w-10 tw-text-center">
            {{ office.reference }}
          </strong>
          <span>
            {{ office.name }}
          </span>
        </div>
      </PanelBox>
      <EntityAuditTrail :project-id="projectId" />
    </div>
    <div class="col-md-6" style="padding-right: 0;">
      <PanelBox title="Kandidaten">
        <span slot="toolbar">
          <a class="toolbar-button" @click="showCandidateHelpModal">
            <i class="fa fa-question" /> Help
          </a>
        </span>
        <table class="tw-table-auto tw-w-full tw-max-w-md">
          <tr>
            <th>Kandidaten nog in aanmerking</th>
            <td v-if="statistics">
              {{ statistics.active_leads_count }}
              <router-link :to="{ name: 'ProjectLeads', query: { status: [0, 1, 4] } }" class="btn-link">
                <i class="fa fa-eye" />
              </router-link>
            </td>
            <td v-else><em>-</em></td>
          </tr>
          <tr>
            <th>Kandidaten nog niet gelinkt aan het project</th>
            <td v-if="statistics">
              {{ statistics.pending_leads_count }}
              <button type="button" @click="showHmodhPropertiesModal" class="btn-link">
                <i class="fa fa-eye" />
              </button>
            </td>
            <td v-else><em>-</em></td>
          </tr>
          <tr>
            <th>Kandidaten die geen interesse meer hebben</th>
            <td v-if="statistics">
              {{ statistics.inactive_leads_count }}
              <router-link :to="{ name: 'ProjectLeads', query: { status: [2] } }" class="btn-link">
                <i class="fa fa-eye" />
              </router-link>
            </td>
            <td v-else><em>-</em></td>
          </tr>
        </table>
      </PanelBox>
      <PanelBox title="Eigenaars">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectOwnersEdit' }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>

        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
          <div>
            <h4>Eigenaars</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div v-for="owner in owners" :key="owner.id">
                <EntityContact :contact="owner" />
              </div>
            </div>
          </div>

          <div>
            <h4>Facturatiecontacten</h4>
            <div class="tw-flex tw-flex-col tw-gap-4">
              <div
                v-for="(invoiceContact, index) in invoiceContacts"
                :key="`invoiceContact_${index}`"
                class="tw-border tw-p-2 tw-rounded"
              >
                <EntityInvoiceContactsDisplay :invoice-contact="invoiceContact" />
              </div>
            </div>
          </div>
        </div>
      </PanelBox>
      <PanelBox title="Commentaar">
        <span slot="toolbar">
          <router-link
            :to="{ name: 'ProjectGeneralEdit', params: { formName: 'comments' } }"
            title="Bewerken"
            class="toolbar-button"
          >
            <i class="fas fa-pencil" /> Bewerken
          </router-link>
        </span>
        <div>
          <pre>{{ project.notes }}</pre>
        </div>
      </PanelBox>
    </div>
    <EntityMapModal
      ref="mapModal"
      :geolocation="geolocation"
      :entity="project"
      :parcelString="project.parcels"
    />
    <CandidateHelpModal ref="candidateHelpModal" />
    <HmodhPropertiesModal ref="hmodhPropertiesModal" :project="project" />
    <ProjectCollaboratorChangeAndTransferConfirmationModal
      ref="collaboratorChangeAndTransferConfirmationModal"
      :project-id="projectId"
      @update="fetchCollaborators(projectId)"
    />
  </div>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import EntityContact from '@/components/properties/EntityContact.vue'
import EntityMapModal from '@/components/properties/EntityMapModal'
import Stickers from '@/components/properties/Stickers'
import EntityAuditTrail from '@/components/properties/EntityAuditTrail'
import CandidateHelpModal from '@/components/properties/CandidateHelpModal'
import HmodhPropertiesModal from '@/components/contacts/hmodh/HmodhPropertiesModal'
import EntityInvoiceContactsDisplay from '@/components/properties/EntityInvoiceContactsDisplay'
import ProjectCollaboratorChangeAndTransferConfirmationModal from '@/components/properties/ProjectCollaboratorChangeAndTransferConfirmationModal'

import { mapActions, mapGetters } from 'vuex'
import {
  getProjectStickers,
  getProjectGeolocation,
  getProjectOwners,
  getProjectCollaborators,
  getProjectOffices,
  makeProjectOfficePrimary,
  getProjectStatistics,
  getProjectInvoiceContacts
} from '@/services/projects'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'ProjectGeneral',
  components: {
    PanelBox,
    EntityContact,
    EntityMapModal,
    Stickers,
    EntityAuditTrail,
    CandidateHelpModal,
    HmodhPropertiesModal,
    EntityInvoiceContactsDisplay,
    ProjectCollaboratorChangeAndTransferConfirmationModal
  },
  data () {
    return {
      notes: '',
      stickers: [],
      owners: [],
      collaborators: [],
      offices: [],
      geolocation: {},
      statistics: {},
      invoiceContacts: []
    }
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),

    projectId () {
      return this.$route.params.id
    },
    project () {
      return this.getProjectById(this.projectId)
    },
    isArchived () {
      return this.project?.status === 99
    },
    projectTitle () {
      const status_display = this.isArchived
        ? `(${this.project?.status_display.toLowerCase()})`
        : ''
      return `${this.project?.name} ${status_display}`
    },
    googleMapsAddress () {
      if (this.project && this.project.city) {
        return `http://maps.google.com/maps?&q=${this.project.street}+${this.project.number}+${this.project.box}+${this.project.city.name}`
      }
      return ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadProject']),

    showMapModal () {
      this.$refs.mapModal.show()
    },
    async init () {
      const response = await Promise.all([
        this.fetchProjectGeolocation(this.projectId),
        this.fetchInvoiceContacts(this.projectId),
        this.fetchProjectStickers(this.projectId),
        this.fetchProjectOwners(this.projectId),
        this.fetchCollaborators(this.projectId),
        this.loadStatistics(this.projectId),
        this.fetchOffices(this.projectId)
      ])
      this.notes = this.project.notes
      return response
    },
    async fetchProjectStickers (projectId) {
      const response = await getProjectStickers(projectId)
      this.stickers = response.data?.results
      return response
    },
    async fetchProjectGeolocation (projectId) {
      const response = await getProjectGeolocation(projectId)
      this.geolocation = response.data
      return response
    },
    async fetchProjectOwners (projectId) {
      const response = await getProjectOwners(projectId)
      this.owners = response.data
      return response
    },
    async fetchCollaborators (projectId) {
      const payload = {
        projectId,
        params: {
          include_inactive: 1
        }
      }
      const response = await getProjectCollaborators(payload)
      this.collaborators = response.data?.results
      return response
    },
    async fetchOffices (projectId) {
      const response = await getProjectOffices(projectId)
      this.offices = response.data?.results
      return response
    },
    async fetchInvoiceContacts (projectId) {
      const response = await getProjectInvoiceContacts(projectId)
      this.invoiceContacts = response.data?.results
      return response
    },
    async makeCollaboratorPrimary (collaborator) {
      this.$refs.collaboratorChangeAndTransferConfirmationModal.show(collaborator)
    },
    async makeOfficePrimary (office_id) {
      try {
        const response = await makeProjectOfficePrimary(this.projectId, { office_id })
        await this.fetchOffices(this.projectId)
        successModal('Het kantoor is primair gemaakt.')
        return response
      } catch (error) {
        let errorMessage = 'Kan kantoor niet niet primair maken.'
        if (error.response?.data?.code === 'PORTAL_PUBLICATIONS_EXIST') errorMessage = 'Kan kantoor niet primair maken omdat er actieve portaalpublicaties zijn.'
        errorModal(errorMessage)
      }
    },
    showCandidateHelpModal () {
      this.$refs.candidateHelpModal.show()
    },
    async loadStatistics (projectId) {
      try {
        const response = await getProjectStatistics(projectId)
        this.statistics = response.data
        return response
      } catch {
        errorModal('Er ging iets mis bij het inladen van de statistieken.')
      }
    },
    showHmodhPropertiesModal () {
      this.$refs.hmodhPropertiesModal.show()
    }
  }
}
</script>
